<template>
  <div>
    <v-data-table v-if="!isLoading" :headers="headers" :items="coupons">
      <template v-slot:[`item.actions`]="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-icon size="20" color="black" class="mr-3" v-on="on" @click="editCoupon(item)"> mdi-pencil</v-icon>
          </template>
          Módosítás
        </v-tooltip>
        <v-tooltip v-if="item.numberOfCompanies === 0" bottom>
          <template v-slot:activator="{ on }">
            <v-icon size="20" color="black" v-on="on" @click="openDeleteConfirmator(item)">mdi-delete</v-icon>
          </template>
          Törlés
        </v-tooltip>
      </template>
    </v-data-table>

    <v-skeleton-loader type="table" v-else></v-skeleton-loader>
    <ConfirmDialog :confirm-handler="deleteConfirmHandler"></ConfirmDialog>
    <CouponDialog></CouponDialog>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { DELETE_COUPON } from '@/store/types'

const ConfirmDialog = () => import('@/components/Global/ConfirmDialog.vue')
const CouponDialog = () => import('@/components/Coupons/CouponDialog.vue')

export default {
  name: 'CouponsTable',
  components: {
    ConfirmDialog,
    CouponDialog
  },
  data() {
    return {
      headers: [
        {
          text: '#',
          sortable: false,
          value: 'id'
        },
        {
          text: 'Név',
          align: 'start',
          value: 'name'
        },
        {
          text: 'Kód',
          align: 'start',
          value: 'coupon_code'
        },
        {
          text: '',
          value: 'actions',
          align: 'end',
          sortable: false
        }
      ],
      deleteConfirmHandler: {}
    }
  },
  computed: {
    ...mapState({
      coupons: state => state.coupons.coupons
    }),
    isLoading() {
      return this.status === 'loading'
    }
  },
  methods: {
    openDeleteConfirmator(resource) {
      this.deleteConfirmHandler = {
        handler: () => this.deleteResource(resource),
        title: `${resource.name} kupon törlése`
      }
      this.$modal.show('confirm-dialog')
    },
    async deleteResource(resource) {
      await this.$store.dispatch(DELETE_COUPON, resource.id)
      this.$modal.hide('confirm-dialog')
    },
    editCoupon(coupon) {
      this.$modal.show('coupon-dialog', {
        coupon
      })
    }
  }
}
</script>
