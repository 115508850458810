<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-card :loading="isLoading">
          <v-card-title>Kuponok</v-card-title>
          <v-col class="text-right">
            <v-btn color="primary" small @click="createResource">Új kupon</v-btn>
          </v-col>
          <v-card-text>
            <CouponsTable />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from 'vuex'

import { GET_COUPONS_REQUEST } from '@/store/types'

import CouponsTable from '@/components/Coupons/CouponsTable.vue'

export default {
  name: 'PageCoupons',
  components: {
    CouponsTable
  },
  computed: {
    ...mapState({
      status: state => state.plans.status
    }),
    isLoading() {
      return this.status === 'loading'
    }
  },
  created() {
    this.$store.dispatch(GET_COUPONS_REQUEST)
  },
  methods: {
    createResource() {
      this.$modal.show('coupon-dialog', {
        isNewCoupon: true
      })
    }
  }
}
</script>
